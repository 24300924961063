<template>
  <div class="app-container">
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="75%"
    >



    <div>
      <el-form layout="inline">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="人员">
              <el-select v-model="queryParam.userSign" placeholder="请选择"    value-key="memberSign" clearable>
                <el-option
                  v-for="item in memberSignList"
                  :key="item.memberSign"
                  :label="item.userName"
                  :value="item.memberSign"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-button @click="selectDataListButton()">查询</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <el-button
        class="el-icon-plus"
        round
        style="margin: 0px 10px"
        @click="trafficAddButton"
      />
      <el-table :data="projectMember" ref="table" stripe show-summary>
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column
          label="操作"
          prop="trafficMoney"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-setting"
              round
              v-if="scope.row.status != 2"
              @click.native.prevent="
                addButton(scope.$index, scope.row, projectMember)
              "
            ></el-button>
            <!-- v-if="scope.row.status === 1" -->

            <el-button
              size="mini"
              icon="el-icon-minus"
              round
              v-if="scope.row.status != 2"
              @click.native.prevent="
                deleteRow(scope.$index, scope.row, projectMember)
              "
            />
            <el-button
              size="mini"
              round
              icon="el-icon-lock"
              v-if="scope.row.status === 1 && scope.row.id != null"
              @click="lockButton(scope.$index, scope.row, 2)"
            />
            <el-button
              size="mini"
              round
              icon="el-icon-unlock"
              v-if="scope.row.status === 2"
              @click="lockButton(scope.$index, scope.row, 1)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="成员"
          prop="memberSign"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.memberSign"
              :disabled="scope.row.status == 2"
              value-key="memberSign"
              placeholder="请选择"
            >
              <el-option
                v-for="item in memberSignList"
                :key="item.memberSign"
                :label="item.userName"
                :value="item.memberSign"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          label="时间"
          prop="timeSolt"
          align="center"
          width="400"
        >
          <template slot-scope="scope">
            <el-date-picker
              :disabled="scope.row.status == 2"
              v-model="scope.row.timeSolt"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="(val) => handleDateChange(scope.row, val,scope.$index)"
            >
            </el-date-picker>
          </template>
        </el-table-column>

        <el-table-column label="人天" prop="mantion" align="center" width="200">
          <template slot-scope="scope">
            <el-input
              :disabled="scope.row.status == 2"
              v-model="scope.row.mantion"
              type="number"
            />
          </template>
        </el-table-column>

        <el-table-column
          label="报销金额"
          prop="realityMoney"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-input
              :disabled="scope.row.status == 2"
              v-model="scope.row.realityMoney"
              type="number"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="人天成本"
          prop="personMoney"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-input
              :disabled="scope.row.status == 2"
              v-model="scope.row.personMoney"
              type="number"
            />
          </template>
        </el-table-column>

        <el-table-column
          label="描述"
          prop="describes"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-input
              :disabled="scope.row.status == 2"
              v-model="scope.row.describes"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          prop="status"
          align="center"
          width="200"
          :formatter="statusEnum"
        />
      </el-table>
    </el-dialog>
  </div>
</template>


<script>
import {
  selectpersonrecordsByIdApi,
  deletepersonrecordsByIdApi,
  editpersonrecordsApi,
  addpersonrecordsApi,
  listpersonrecordsApi,
  lockByIdApi,
} from "@/api/work/project/personrecords";
import { getDaysBetween } from "@/utils/timeUtils";
import { getProjectPersonApi } from "@/api/work/project/project";

export default {
  data() {
    return {
      // 选中数组
      radio: null,
      ids: null,
      // 查询参数
      queryParam: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      total: 0,
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataColumnList: [],
      dialog: false,
      loading: false,
      projectMember: [],
      types: 1,
      title: null,
      personList: [],
      projectCode: null,
      memberSignList: [],
      statusList: [
        {
          key: 1,
          label: "待确定",
        },
        {
          key: 2,
          label: "锁定",
        },
      ],
    };
  },
  methods: {
    show(row, type) {
      this.types = type;
      this.projectCode = row.code;
      this.dialog = true;
      this.initPerson(row);
      this.selectDataListButton(row);
      this.title = "查看";
    },

    selectDataListButton() {
      listpersonrecordsApi({ projectCode: this.projectCode }).then((res) => {
        this.projectMember = res.result;
        if(this.queryParam.userSign!=null){
         const newData=  this.projectMember.filter(item=>item.memberSign===this.queryParam.userSign);
         this.projectMember=newData;
        }
      });
    },
    initPerson(row) {
      getProjectPersonApi({ projectCode: row.code }).then((res) => {
        this.memberSignList = res.result;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.ids = row.id;
      this.optFrom = row;
    },
    addButton(index, rows, dataList) {
      console.log("----", rows.id);
      if (rows.id != null) {
        rows.startTime = rows.timeSolt[0];
        rows.endTime = rows.timeSolt[1];
        editpersonrecordsApi(rows).then((res) => {
          this.$set(this.projectMember, index, {
            ...this.projectMember[index],
            id: res.result.id,
            status: res.result.status,
          });
        });
      } else {
        //新增
        rows.startTime = rows.timeSolt[0];
        rows.endTime = rows.timeSolt[1];
        addpersonrecordsApi(rows).then((res) => {
          this.$set(this.projectMember, index, {
            ...this.projectMember[index],
            id: res.result.id,
            status: res.result.status,
          });
        });
        //编辑
      }
    },
    lockButton(index, row, type) {
      lockByIdApi({ id: row.id, status: type }).then((res) => {
        this.$set(this.projectMember, index, {
          ...this.projectMember[index],
          id: res.result.id,
          status: res.result.status,
        });
      });
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
      this.projectMember = [];
      this.projectCode = null;
      this.memberSignList = [];
      this.$parent.selectDataListButton();
    },
    trafficAddButton() {
      var data = {
        id: null,
        projectCode: this.projectCode,
        status: 0,
      };
      this.projectMember.push(data);
    },
    deleteRow(index, rows, dataList) {
      if (dataList[index].id != null && dataList[index].id != undefined) {
        //需要调用删除接口
        deletepersonrecordsByIdApi(dataList[index].id).then((res) => {
          this.selectDataListButton(rows);
        });
      } else {
        dataList.splice(index, 1);
      }
    },
    statusEnum(row, column) {
      if (row.status == 1) {
        return "草稿";
      }
      if (row.status == 2) {
        return "锁定";
      }
    },
    handleDateChange(row, value,index) {
      console.log("----",  row,value);
      const date1 = new Date(value[0]);
      const date2 = new Date(value[1]);
      this.$set(this.projectMember, index, {
          ...this.projectMember[index],
          mantion: getDaysBetween(date1, date2)+1
        });
    },
  },
};
</script>



