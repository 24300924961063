import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/projectPersonRecords'


const listpersonrecordsApi =(params)=>getAction(baseURL+"/list",params);

const addpersonrecordsApi=(params)=>postAction(baseURL+"/add",params);

const editpersonrecordsApi=(params)=>putAction(baseURL+"/edit",params);

const deletepersonrecordsByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectpersonrecordsByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

const lockByIdApi=(params)=>getAction(baseURL+'/lock',params);


export{
    lockByIdApi,
    selectpersonrecordsByIdApi,
    deletepersonrecordsByIdApi,
    editpersonrecordsApi,
    addpersonrecordsApi,
    listpersonrecordsApi
}
